import React, { useState } from 'react';
import { Divider } from 'antd';
import Navbar from './Navbar'


const About = () => {
  return (
    <div>
        <Navbar />


        <Divider
          style={{
            borderColor: '#7cb305',
          }}
        >
          Who
        </Divider>
      <p>
        Who we are, as far as we now, we are humans.
      </p>
      <Divider
          style={{
            borderColor: '#7cb305',
          }}
        >
          What
        </Divider>
      <p>
          We make applications that is software as a service.
      </p>

      <Divider
          style={{
            borderColor: '#7cb305',
          }}
        >
          Why
        </Divider>
      <p>
          That is a big question - but mostly because we like to make task handling easier.
      </p>
    </div>
  );
};

export default About;

