import React, { useEffect, useState } from 'react'
import { Flex, Spin, Space, Table } from 'antd';
import { useNavigate } from "react-router-dom";
import { Button, Form, Input, Radio } from 'antd';
import { useSearchParams } from "react-router-dom";
import { Divider } from 'antd';

import Navbar from './Navbar'  
import GlobalObject from './globalObject';

const TaskEdit = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const [isSavingData, setIsSavingData] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [taskData, setTaskData] = useState();
    const [dataHeadline, setHeadline] = useState("");
    const [dataDescription, setDescription] = useState("");

    const [searchParams, setSearchParams] = useSearchParams();
    const taskParentGuid = searchParams.get("taskParent")

    const navigate = useNavigate();

    function GoHome()
    {
        navigate('/taskroot');
    }

    function GoParent()
    {
        setTaskData([]);
        navigate('/taskedit?taskGuid=' + taskParentGuid);
    }

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' ,
                   'Authorization': 'Bearer ' + localStorage.getItem(GlobalObject.token)},
      };
    
      useEffect(() => {
        setLoading(true)

        if (taskParentGuid == null)
        {
            setLoading(false);
            setTaskData([]);
            return;
        }

        fetch(GlobalObject.url_api +  '/v1/task/' + taskParentGuid,  requestOptions )
          .then(response => response.json())
          .then(json => setTaskData(json))
          .finally(() => {
            setLoading(false)
          })
      }, [])

      const saveForm = async () => {
        try {
            setIsSavingData(true);

            var obj = {};
            obj['headline'] = dataHeadline;
            obj['description'] = dataDescription;
            const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' ,
                         'Authorization': 'Bearer ' + localStorage.getItem(GlobalObject.token)},
              body: JSON.stringify(obj)
            };

            console.log(JSON.stringify(obj));

            fetch(GlobalObject.url_api +  '/v1/task/' + taskParentGuid + '/subtask',  requestOptions )
        }
        finally {
            setIsSavingData(false);
            setIsSaved(true);
        }
    }


    return (
        <div>
            <div>
                <Navbar />
            </div>
            <div>
                <h1>Task information</h1>
            </div>

            <div>
                <Space size="middle">
                    <Button type="primary" onClick={GoParent}>To Parent</Button>
                    <Button type="primary" onClick={GoHome}>To Root</Button>
                </Space>
            </div>

            <Divider>Parent task</Divider>

              {loading ? (
                    <div  style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                        <Flex gap="middle">
                        <Spin tip="" size="large">Loading tasks...</Spin>
                        </Flex> 
                    </div>
              ) : (
                <div>
                    <h2>Parent task: {taskData.headline}</h2>
                    <h2>{taskData.description}</h2>

                    <Divider>Task information</Divider>

                    {loading ? (
                        <div  style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                            <Flex gap="middle">
                            <Spin tip="" size="large">Loading tasks...</Spin>
                            </Flex> 
                        </div>
                    ) : (
                    <div>
                        <Form>

                            <Form.Item label="Headline">
                            <Input placeholder="Task headline" value={dataHeadline} onChange={e => {
                                setHeadline(e.target.value); 
                                setIsSaved(false);
                                }} />
                            </Form.Item>
                            <Form.Item label="Description">
                            <Input placeholder="Task description" value={dataDescription} onChange={e =>
                                {
                                    setIsSaved(false);
                                    setDescription(e.target.value);
                                }} />
                            </Form.Item>
                            <Form.Item >

                            {!isSavingData && !isSaved && (
                                <Button type="primary" onClick={saveForm}>Save</Button>
                                )
                            }

                            {isSavingData ?
                                <div  style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                                <Flex gap="middle">
                                    <Spin size="large"></Spin>
                                </Flex> 
                                </div>
                                : null}

                            </Form.Item>
                            </Form>
                    </div>
                    )}
                    {
                    isSaved && (
                        <div>SAVED</div>

                    )
                    }

                </div>
            )}
        </div>
        

    );
  };

 export default TaskEdit;