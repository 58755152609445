 //App.js
 import { Route, Routes } from 'react-router-dom';
 import './App.css';
 import Home from './Home';
 import About from './About';
 import Login from './Login';
 import TaskEdit from './TaskEdit';
 import NewSubTask from './NewSubTask';
 import NewRootTask from './NewRootTask';
 import TaskRoot from './TaskRoot';
 import Logout from './Logout';
 
 function App() {
   return (
    <div className="App">
       <Routes>
         <Route path='/' element={<Home/>} />
         <Route path='/about' element={<About/>} />
         <Route path='/login' element={<Login/>} />
         <Route path='/logout' element={<Logout/>} />
         <Route path='/taskroot' element={<TaskRoot/>} />
         <Route path='/taskedit' element={<TaskEdit/>} />
         <Route path='/newsubtask' element={<NewSubTask/>} />
         <Route path='/newroottask' element={<NewRootTask/>} />
       </Routes>
     </div>
        );
 }

 export default App;