import React, { useState } from 'react';
import { Button, Input, Space } from 'antd';
import Navbar from './Navbar'
import GlobalObject from './globalObject';

const Logout = () => {

  const [loggedOut, SetLoggedOut] = useState(false);
  
  const handleClick = async () => {
    try {
      localStorage.removeItem(GlobalObject.token)
      SetLoggedOut(true);
      window.location.reload();

    } catch (err) {
        console.log(err.message)
    }
    finally{
    }
  }

  return (
    <div>
      <Navbar />

      <div><h1>Leaving H.O.T - so soon?</h1></div>

      <div>
          <Space direction="vertical">

          {localStorage.getItem(GlobalObject.token) != null ?
            <div tyle={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
              <Button type="primary" onClick={handleClick}>Logout</Button>
            </div>
          : 
            <div tyle={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
              Loggded out
            </div>
            }
          </Space>

        </div>
    </div>
  );
};

export default Logout;