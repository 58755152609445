import React, { useEffect, useState } from 'react'
import { Flex, Spin, Space, Table } from 'antd';
import { useNavigate } from "react-router-dom";
import { Button, Form, Input, Radio } from 'antd';
import { useSearchParams } from "react-router-dom";
import { Divider } from 'antd';

import Navbar from './Navbar';
import GlobalObject from './globalObject';

const TaskEdit = () => {
    const [dataTaskGuid, setTaskGuid] = useState("");
    const [dataHeadline, setHeadline] = useState("");
    const [subTasks, setSubTasks] = useState([]);
    const [dataDescription, setDescription] = useState("");

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const [isSavingData, setIsSavingData] = useState(false);
    const [isSaved, setIsSaved] = useState(true);

    const [searchParams, setSearchParams] = useSearchParams();
    const taskGuid = searchParams.get("taskGuid")

    const navigate = useNavigate();

    const requestOptions = {
        method: 'GET',
        headers: 
            { 'Content-Type': 'application/json' ,
              'Authorization': 'Bearer ' + localStorage.getItem(GlobalObject.token)},
        
    };
    
    function GoHome()
    {
        navigate('/taskroot');
    }

    function GoNew()
    {
        navigate('/newsubtask?taskParent=' + taskGuid);
    }

    function itemViewClick(task)
    {
        console.log("DO navigate:" + task.record.taskGuid);
        navigate('/taskedit?taskGuid=' + task.record.taskGuid, {state: GlobalObject})
        window.location.reload();
    }

    const saveForm = async () => {
        try {
            setIsSavingData(true);

            var obj = {};
            obj['headline'] = dataHeadline;
            obj['description'] = dataDescription;
            const requestOptions = {
              method: 'PUT',
              headers: { 'Content-Type': 'application/json' ,
                         'Authorization': 'Bearer ' + localStorage.getItem(GlobalObject.token)},
              body: JSON.stringify(obj)
            };

            fetch(GlobalObject.url_api +  '/v1/task/' + taskGuid,  requestOptions )
        }
        finally {
            setIsSavingData(false);
            setIsSaved(true);
        }
    }
    
    useEffect(() => {
        setLoading(true);
        setHeadline("");
        setDescription("");
        setSubTasks([]);

        if (taskGuid == null)
        {
            setLoading(false);
            return;
        }

        const fetchDetails = async () => {
            try {
                const resTaskData = await fetch(GlobalObject.url_api +  '/v1/task/' + taskGuid,  requestOptions );
                const taskData = await resTaskData.json();
                setHeadline(taskData.headline);
                setDescription(taskData.description);
                setTaskGuid(taskData.taskGuid);
    
                const resSubTasks = await fetch(GlobalObject.url_api +  '/v1/task/' + taskGuid + '/subtask/owned',  requestOptions )
                const subTask = await resSubTasks.json();
                setSubTasks(subTask);
            }
            finally{
                setLoading(false); 
            }
        }

        fetchDetails();
      }, [])

    const columns = [
        {
          title: 'Headline',
          dataIndex: 'headline',
          key: 'headline',
        },
        {
          title: 'Description',
          dataIndex: 'description',
          key: 'description',
        },
        {
          title: 'Action',
          key: 'action',
          render: (t, record) => (
            <Space size="middle">
              <a onClick={() => itemViewClick({record})}>View</a>
            </Space>
          ),
        },
      ];
    

    return (
        <div>
            <div>
                <Navbar />
            </div>
            <div>
                <h1>Task information</h1>
            </div>

            <div>
                <Space size="middle">

                    <Button type="primary" onClick={GoNew}>Create sub task</Button>
                    <Button type="primary" onClick={GoHome}>Goto root</Button>

                </Space>
            </div>

            <Divider>Task information</Divider>

              {loading ? (
                    <div  style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                        <Flex gap="middle">
                        <Spin tip="" size="large">Loading tasks...</Spin>
                        </Flex> 
                    </div>
              ) : (
                <div>
                    <Form>

                        <Form.Item label="Headline">
                        <Input placeholder="Task headline" value={dataHeadline} onChange={e => {
                            setHeadline(e.target.value); 
                            setIsSaved(false);
                            }} />
                        </Form.Item>
                        <Form.Item label="Description">
                        <Input placeholder="Task description"  value={dataDescription} onChange={e =>
                            {
                              setIsSaved(false);
                              setDescription(e.target.value);
                            }} />
                        </Form.Item>
                        <Form.Item >

                        {!isSaved && (
                            <Button type="primary" onClick={saveForm}>Save</Button>
                            )
                        }

                        {isSavingData ?
                            <div  style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                            <Flex gap="middle">
                                <Spin size="large"></Spin>
                            </Flex> 
                            </div>
                            : null}

                        </Form.Item>
                        </Form>
                </div>
            )}

            <div>
                <Table 
                onRow={(record, rowIndex) => {
                    return {
                    onClick: event => { 
                        //console.log(record)
                    },
                }}}
                dataSource={subTasks} 
                columns={columns} 
                rowKey="taskGuid" />

            </div>
        </div>



    );
  };

 export default TaskEdit;