import React, { useEffect, useState } from 'react'
import { Flex, Spin, Space, Table } from 'antd';
import { useNavigate } from "react-router-dom";
import { Button, Form, Input, } from 'antd';
import { useSearchParams } from "react-router-dom";
import { Divider } from 'antd';

import Navbar from './Navbar'  
import GlobalObject from './globalObject';

const NewRootTask = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const [taskData, setTaskData] = useState();
    const [isSavingData, setIsSavingData] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [dataHeadline, setHeadline] = useState("");
    const [dataDescription, setDescription] = useState("");


    const [searchParams, setSearchParams] = useSearchParams();
    const taskParentGuid = searchParams.get("taskParent")

    const navigate = useNavigate();

    function GoHome()
    {
        navigate('/taskroot');
    }

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' ,
                   'Authorization': 'Bearer ' + localStorage.getItem(GlobalObject.token)},
      };
    
      useEffect(() => {
        setLoading(true)

        if (taskParentGuid == null)
        {
            setLoading(false);
            setTaskData([]);
            return;
        }

        fetch(GlobalObject.url_api +  '/v1/task/' + taskParentGuid,  requestOptions )
          .then(response => response.json())
          .then(json => setTaskData(json))
          .finally(() => {
            setLoading(false)
          })
      }, [])

    const saveForm = async () => {
        try {
            setIsSavingData(true);

            var obj = {};
            obj['headline'] = dataHeadline;
            obj['description'] = dataDescription;
            const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' ,
                         'Authorization': 'Bearer ' + localStorage.getItem(GlobalObject.token)},
              body: JSON.stringify(obj)
            };

            console.log(JSON.stringify(obj));

            fetch(GlobalObject.url_api +  '/v1/task/',  requestOptions )
        }
        finally {
            setIsSavingData(false);
            setIsSaved(true);
        }
    }


    return (
        <div>
            <div>
                <Navbar />
            </div>
            <div>
                <h1>New root task</h1>
            </div>

            <div>
                <Space size="middle">
                    <Button type="primary" onClick={GoHome}>Goto root</Button>
                </Space>
            </div>

              {loading ? (
                    <div  style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                        <Flex gap="middle">
                        <Spin tip="" size="large">Loading tasks...</Spin>
                        </Flex> 
                    </div>
              ) : (
                <div>
                    <Divider>Task information</Divider>
                    <Form>

                        <Form.Item label="Headline">
                        <Input placeholder="Task headline" onChange={e => {
                            setHeadline(e.target.value); 
                            setIsSaved(false);
                            }} />
                        </Form.Item>
                        <Form.Item label="Description">
                        <Input placeholder="Task description" onChange={e =>
                            {
                              setIsSaved(false);
                              setDescription(e.target.value);
                            }} />
                        </Form.Item>
                        <Form.Item >
                        {!isSavingData && !isSaved && (
                            <Button type="primary" onClick={saveForm}>Save</Button>
                            )
                        }
                        </Form.Item>


                        {isSavingData ?
                            <div  style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                            <Flex gap="middle">
                                <Spin size="large"></Spin>
                            </Flex> 
                            </div>
                            : null}


                    </Form>
                </div>
            )}

            {
                isSaved && (
                    <div>SAVED</div>

                )
            }

        </div>
        

    );
  };

 export default NewRootTask;